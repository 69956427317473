import React from 'react';
import './App.css';
import { Divider } from '@mui/material';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Icecream from './Images/ice-cream.png'
import ShenShenAd from './Images/ShenShenAd.mp4'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneTwoToneIcon from '@mui/icons-material/LocalPhoneTwoTone';
import { Helmet } from 'react-helmet';


const theme = createTheme({
    palette: {
        primary: {
            main: "#CFFCFA", // custom primary color
        },
        secondary: {
            main: "#D6AFB1", // custom secondary color
        },
    },
});

const customFontStyle = {
    fontFamily: 'Kalam', // Replace 'YourCustomFont' with the actual font name
    fontWeight: 'bold',
};

export default function Home() {
    return (
        <div>
            <Helmet>
                <title data-react-helmet="true">Shen Shen Ice Cream | Handcrafted Italian-Style Ice Cream in Hurghada, Egypt</title>
                <meta data-react-helmet="true" name="description" content="Indulge in happiness at ShenShen Ice Cream, where we handcraft Italian-style ice cream using the purest ingredients. Explore unique flavors made with fresh, locally sourced ingredients and organic milk. Join us for an unforgettable ice cream experience in Hurghada, Egypt." />
                <meta data-react-helmet="true" property="og:title" content="ShenShen Ice Cream | Handcrafted Italian-Style Ice Cream in Hurghada, Egypt" />
                <meta data-react-helmet="true" property="og:description" content="Indulge in happiness at ShenShen Ice Cream, where we handcraft Italian-style ice cream using the purest ingredients. Explore unique flavors made with fresh, locally sourced ingredients and organic milk. Join us for an unforgettable ice cream experience in Hurghada, Egypt." />
                <meta data-react-helmet="true" property="og:image" content="https://webdesignexpressions.net/Assets/ShenShen.jpg" />
                <meta data-react-helmet="true" property="og:url" content="https://shenshen.webdesignexpressions.net/" />
                <meta data-react-helmet="true" property="og:type" content="website" />
            </Helmet>
            <div className='p-12'>



                <div><div className='pb-5' ><h1 className='text-4xl font-bold custom-font'>Welcome</h1></div></div>
                <div className='lg:float-right ml-2 lg:w-1/2 mx-auto'>
                    <div>

                        <video src={ShenShenAd} type="video/mp4" autoPlay loop muted></video>
                    </div>
                    <div className='py-5'><Divider /></div>
                </div>

                <div className='custom-font text-xl'><div className='pb-5'>to ShenShen Ice Cream, where we handcraft Italian-style ice cream using the purest and highest quality ingredients to bring you the freshest and most delicious flavors. Our story began in 2011 when two brothers, Deda Farid and Maged Farid, decided to pursue their passion for ice cream and bring homemade goodness to Hurghada, Egypt.</div></div>
                <div className='custom-font text-xl'><div className='pb-5'>Deda was always crazy about ice cream since he was a kid, and he realized that there was no good "homemade" ice cream in the city. So, he spoke to his brother Maged, and they decided to do it themselves. Their family loved the idea and supported them. They began extensive research and traveled all over the world to learn from the best artisans to make the freshest top-quality ice cream. After years of hard work and dedication, they opened the first Italian homemade ice cream shop in Hurghada, with one concept in mind, "the Highest Quality, 100% Natural Ice Cream," with no artificial flavors, coloring or preservatives.</div></div>
                <div><div className='pb-5'><h2 className='text-4xl font-bold custom-font'>This is what makes our brand extraordinary</h2></div></div>
                <div className='lg:float-left lg:w-1/3 lg:mr-5'>
                    <img src={Icecream} alt='affordable ice cream website' title='Delicious Ice Cream Cones - Unforgettable Flavors at ShenShen Ice Cream' />
                </div>
                <div className='custom-font text-xl'><div className='pb-5'>Our brand is extraordinary, and we take pride in using only the purest and highest quality ingredients to create unique flavors that stand out from the rest. When creating our seasonal flavors, we use fresh, locally grown ingredients, and we only use fresh whole milk, making our products healthy and of the highest quality. ShenShen Ice Cream has a unique quality that makes our brand "one of a kind" and very specialized.</div></div>
                <div className='custom-font text-xl'><div className='pb-5'>The milk we use is at the heart of our ice cream making process. We use organic milk sourced from a specific breed of native cow, which has higher fat and protein, allowing for longer protein bonds and low water content, leaving less free water to crystallize in our ice cream. This produces a beautifully silky finish every time, and each recipe is carefully balanced for the ideal consistency, clean flavor, and a soft and silky texture.</div></div>
                <div className='custom-font text-xl'><div className='pb-5'>We believe that ice cream is not just food, but a reward and a treat enjoyed so much more when you're happy or with your loved ones. We strive to bring happiness to our customers through our delicious ice cream and create an unforgettable experience with each visit to our shop. Our mission is to bring you ShenShen happiness and make every moment spent with us a special one.</div></div>
                <div className='custom-font text-xl'><div className='pb-5'>Thank you for choosing ShenShen Ice Cream, and we look forward to serving you!</div></div>


                <div className='lg:flex lg:gap-10'>
                    <div className='lg:w-1/2 mt-3'>
                        <div><ThemeProvider theme={theme}><Button style={customFontStyle} variant='contained' color='primary' size="large" fullWidth><LocalPhoneTwoToneIcon size='large' color='secondary' />&nbsp; Phone</Button></ThemeProvider></div>
                    </div>
                    <div className='lg:w-1/2 mt-3'>
                        <div><ThemeProvider theme={theme}><Button style={customFontStyle} variant='contained' color='primary' size="large" fullWidth><WhatsAppIcon size='large' color='secondary' />&nbsp; WhatsApp</Button></ThemeProvider></div>
                    </div>
                </div>
            </div>
        </div >
    )
}
