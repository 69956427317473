import { Divider } from '@mui/material'
import React from 'react'
import { CompanyName } from './Component/CompanyName'
import { Helmet } from 'react-helmet'

export default function Contact() {
    return (
        <div className='p-12'>
            <Helmet>
                <title data-react-helmet="true">Contact Us | Shen Shen Ice Cream, Hurghada, Egypt</title>
                <meta data-react-helmet="true" name="description" content="Get in touch with Shen Shen Ice Cream. Located in Hurghada, Egypt, visit our Touristic Villages location. Check our opening hours from Monday to Sunday, 12:00 PM to 01:00 AM. Have a question or want to share your feedback? Contact us now!" />
                <meta data-react-helmet="true" property="og:title" content="Contact Us | Shen Shen Ice Cream, Hurghada, Egypt" />
                <meta data-react-helmet="true" property="og:description" content="Get in touch with Shen Shen Ice Cream. Located in Hurghada, Egypt, visit our Touristic Villages location. Check our opening hours from Monday to Sunday, 12:00 PM to 01:00 AM. Have a question or want to share your feedback? Contact us now!" />
                <meta data-react-helmet="true" property="og:image" content="https://webdesignexpressions.net/Assets/ShenShen.jpg" />
                <meta data-react-helmet="true" property="og:url" content="https://shenshen.webdesignexpressions.net/contact" />
                <meta data-react-helmet="true" property="og:type" content="website" />
            </Helmet>
            <div className='lg:flex gap-5 custom-font'>
                <div className='lg:w-1/2'>
                    <div><div className='pb-5'><h1 className='text-4xl font-bold'><CompanyName /></h1></div></div>
                    <address className='pb-5'>
                        Hurghada, Touristic Villages, Hurghada, Red Sea Governorate 1963001, Egypt
                    </address>
                    <div>
                        <div><div className='pb-5'><h3 className='text-xl font-bold'>Opening hours</h3></div></div>
                        <div className='flex'>
                            <div className='w-1/3'><div className='pb-5 font-bold'>Monday:</div></div>
                            <div className='w-2/3'><div className='pb-5'>12:00PM - 01:00AM</div></div>

                        </div>
                        <Divider />

                        <div className='flex mt-2'>
                            <div className='w-1/3'><div className='pb-5 font-bold'>Tuesday:</div></div>
                            <div className='w-2/3'><div className='pb-5'>12:00PM - 01:00AM</div></div>

                        </div>
                        <Divider />

                        <div className='flex mt-2'>
                            <div className='w-1/3'><div className='pb-5 font-bold'>Wednesday:</div></div>
                            <div className='w-2/3'><div className='pb-5'>12:00PM - 01:00AM</div></div>

                        </div>
                        <Divider />

                        <div className='flex mt-2'>
                            <div className='w-1/3'><div className='pb-5 font-bold'>Thursday:</div></div>
                            <div className='w-2/3'><div className='pb-5'>12:00PM - 01:00AM</div></div>

                        </div>
                        <Divider />

                        <div className='flex mt-2'>
                            <div className='w-1/3'><div className='pb-5 font-bold'>Friday:</div></div>
                            <div className='w-2/3'><div className='pb-5'>12:00PM - 01:00AM</div></div>

                        </div>
                        <Divider />

                        <div className='flex mt-2'>
                            <div className='w-1/3'><div className='pb-5 font-bold'>Saturday:</div></div>
                            <div className='w-2/3'><div className='pb-5'>12:00PM - 01:00AM</div></div>

                        </div>
                        <Divider />

                        <div className='flex mt-2'>
                            <div className='w-1/3'><div className='pb-5 font-bold'>Sunday:</div></div>
                            <div className='w-2/3'><div className='pb-5'>12:00PM - 01:00AM</div></div>

                        </div>
                        <Divider />


                    </div>
                </div>
                <div className='lg:w-1/2'>

                    <div><iframe className="rounded-3xl shadow-2xl border-4 border-white mx-auto w-full aspect-video align-middle" title='Shen Shen map' src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d113557.53308230918!2d33.75329140149287!3d27.198015396508637!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1682816335224!5m2!1sen!2sus" width="600" height="450" style={{ border: '0px' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
                </div>
            </div>
        </div>
    )
}
