import React from 'react'
import { Link, Divider } from '@mui/material'
import { CompanyName } from './CompanyName';
import Developed from './Developed';

const today = new Date();

export default function Footer() {
    return (
        <div className='px-12'>
            <Divider />


            <div className='pt-5 custom-font'><div variant="button" ><Link to='/Home' title='Shen Shen Ice Cream, Hurghada, Egypt' className=' text-black' exact="true" style={{ textDecoration: 'none', color: '#000000' }}>&copy; {today.getFullYear()} - <CompanyName /></Link></div></div>
            <div className='py-1'><Developed /></div>
        </div>
    )
}
