
import Deda from './Images/Deda.png'
import Maged from './Images/Maged.png'
import { CompanyName } from './Component/CompanyName'
import { Helmet } from 'react-helmet';

export default function About() {

    return (
        <div className='p-12 custom-font text-xl'>
            <Helmet>
                <title data-react-helmet="true">About Shen Shen Ice Cream | Hurghada, Egypt</title>
                <meta data-react-helmet="true" name="description" content="Discover the story of Shen Shen Ice Cream, a unique and specialized brand offering the highest quality, 100% natural Italian homemade ice cream in Hurghada, Egypt. Founded by brothers Deda and Maged Fareed, we use authentic recipes and the purest imported ingredients to create the best ice cream in town since 2011." />
                <meta data-react-helmet="true" property="og:title" content="About Shen Shen Ice Cream | Handcrafted Italian Homemade Ice Cream in Hurghada, Egypt" />
                <meta data-react-helmet="true" property="og:description" content="Discover the story of Shen Shen Ice Cream, a unique and specialized brand offering the highest quality, 100% natural Italian homemade ice cream in Hurghada, Egypt. Founded by brothers Deda and Maged Fareed, we use authentic recipes and the purest imported ingredients to create the best ice cream in town since 2011." />
                <meta data-react-helmet="true" property="og:image" content="https://webdesignexpressions.net/Assets/ShenShen.jpg" />
                <meta data-react-helmet="true" property="og:url" content="https://shenshen.webdesignexpressions.net/About" />
                <meta data-react-helmet="true" property="og:type" content="website" />
            </Helmet>




            <div className='lg:float-left mr-5 mb-5 '>
                <div className='border-b-8 border-[#D6AFB1]'><img src={Deda} alt='deda fareed' title='DEDA FAREED' /></div>
                <div className='pt-3 text-center custom-font font-bold text-4xl'><div variant="overline">Deda Fareed</div></div>
            </div>
            <div className='lg:float-right ml-5 mb-5 '>
                <div className='border-b-8 border-[#D6AFB1]'><img src={Maged} alt='maged fareed' title='Maged Fareed' /></div>
                <div className='pt-3 text-center custom-font font-bold text-4xl'><div variant="overline">Maged Fareed</div></div>
            </div>
            <div><div className='pb-5'><CompanyName /> is a unique and specialized brand that offers the highest quality, 100% natural Italian homemade ice cream. Founded by two brothers, Deda and Maged Fareed, who were passionate about ice cream and wanted to create a top-quality product in Hurghada, Egypt.</div></div>
            <div><div className='pb-5'>They traveled extensively all over the world to learn from the best artisans to make the freshest and most delicious ice cream. The result of their hard work and dedication is the best ice cream in town, made fresh daily in Hurghada since 2011.</div></div>
            <div><div className='pb-5'>What sets <CompanyName /> Ice Cream apart from others is the use of authentic recipes and the purest ingredients of the highest quality, imported directly from Italy. They also use fresh, locally grown ingredients when creating seasonal flavors to ensure the best taste.</div></div>
            <div><div className='pb-5'>One of the key ingredients in their ice cream is the organic milk sourced from a specific breed of native cow. The milk has higher fat and protein and low water content, which creates a beautifully silky finish every time. Each recipe is carefully balanced for the ideal consistency, clean flavor, and a soft and silky texture.</div></div>
            <div><div className='pb-5'>At <CompanyName /> Ice Cream, they believe that ice cream is more than just food. It's a treat, a reward, and a source of happiness. They bring happiness to their customers through their delicious ice cream, amazing waffles, delicious ice cream tortes, and crazy milkshakes.</div></div>
            <div><div>So if you're looking for the best ice cream in Hurghada, look no further than <CompanyName /> Ice Cream. You won't find a more specialized and unique brand that offers high-quality Italian homemade ice cream made fresh daily with natural flavors.</div></div>
        </div>
    )
}
