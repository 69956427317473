import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "@mui/material";
import Shen from '../Images/ShenShen.png'
import p1 from "../Images/ShenShen1.jpg";
import p2 from "../Images/ShenShen2.jpg";
import p3 from "../Images/ShenShen3.jpg";
import p4 from "../Images/ShenShen4.jpg";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";

const images = [{ src: p1 }, { src: p2 }, { src: p3 }, { src: p4 }];

const Slideshow = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const previous = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const next = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative">
      <Link href="/">
        <img
          src={Shen}
          alt="affordable ice cream website, professional web design company"
          title="Shen Shen Ice Cream"
          className="absolute top-20 left-20 object-contain z-10 animate-bounce"
        />
      </Link>
      <div className="flex items-center justify-center w-full h-[450px]">
        {images.map((image, index) => (
          <motion.img
            key={image.src}
            src={image.src}
            alt={image.alt}
            className={`absolute object-cover top-0 left-0 w-full h-full ${index === currentIndex ? "opacity-100" : "opacity-0"
              }`}
            initial={{ opacity: 0 }}
            animate={{ opacity: index === currentIndex ? 1 : 0 }}
            transition={{ duration: 1.5 }}
          />
        ))}
        <motion.div
          className="absolute z-10"
          initial={{ x: -1000 }} // starting position outside the screen on the left
          animate={{ x: 0 }} // animate to the original position (0)
          transition={{ duration: 0.5 }} // transition duration in seconds
        ></motion.div>
      </div>
      <button className="absolute top-[190px] left-4 print:hidden hidden" onClick={previous}>
        <ArrowCircleLeftRoundedIcon sx={{ fontSize: 40 }} className="text-white" />
      </button>
      <button className="absolute top-[190px] right-4 print:hidden hidden" onClick={next}>
        <ArrowCircleRightRoundedIcon sx={{ fontSize: 40 }} className="text-white" />
      </button>
    </div>
  );
};

export default Slideshow;
