import React from 'react'
import { Button } from '@mui/material'
import IcecreamIcon from '@mui/icons-material/Icecream';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Helmet } from 'react-helmet';
import Factory from './Component/Factory.json'

const theme = createTheme({
    palette: {
        primary: {
            main: "#CFFCFA", // custom primary color

        },
        secondary: {
            main: "#CFD1FC", // custom secondary color
        },
    },
});

const customFontStyle = {
    fontFamily: 'Kalam', // Replace 'YourCustomFont' with the actual font name
    fontWeight: 'bold',
};

export default function Products() {
    return (
        <div className='p-12'>
            <Helmet>
                <title data-react-helmet="true">Delicious Ice Cream Flavors | Hurghada, Egypt</title>
                <meta data-react-helmet="true" name="description" content="Indulge in a delightful array of handcrafted ice cream flavors at Shen Shen Ice Cream. From classic Vanilla Bean to unique creations like Caramel Macchiato and White Chocolate Raspberry, our ice creams are made with the finest ingredients for a truly heavenly taste. Discover our delicious offerings in Hurghada, Egypt." />
                <meta data-react-helmet="true" property="og:title" content="Delicious Ice Cream Flavors | Shen Shen Ice Cream, Hurghada, Egypt" />
                <meta data-react-helmet="true" property="og:description" content="Indulge in a delightful array of handcrafted ice cream flavors at Shen Shen Ice Cream. From classic Vanilla Bean to unique creations like Caramel Macchiato and White Chocolate Raspberry, our ice creams are made with the finest ingredients for a truly heavenly taste. Discover our delicious offerings in Hurghada, Egypt." />
                <meta data-react-helmet="true" property="og:image" content="https://webdesignexpressions.net/Assets/ShenShen.jpg" />
                <meta data-react-helmet="true" property="og:url" content="https://shenshen.webdesignexpressions.net/Products" />
                <meta data-react-helmet="true" property="og:type" content="website" />
            </Helmet>
            <div className="container mx-auto py-8">
                <div className='lg:text-right custom-font font-bold text-4xl pb-5'>Delight in Organic Ice Cream Goodness</div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">

                    {Factory.map((product) => (
                        <div
                            key={product.id}
                            className="p-4 rounded-lg shadow-md"
                        >
                            <div className=''>

                                <h2 className="text-xl custom-font font-bold"><IcecreamIcon size="large" /> {product.primaryText}</h2></div>

                            <div className='lg:flex'>


                                <div className=''>


                                    <div className="flex">

                                        {product.color.map((color, index) => (
                                            <div className='rounded-full border-[1px] border-[#CFFCFA] my-2'
                                                key={index}
                                                style={{ backgroundColor: color, width: '20px', height: '20px', marginRight: '5px' }}
                                            ></div>
                                        ))}
                                    </div>
                                    <div className='custom-font'>{product.secondaryText}</div>

                                </div>
                            </div>

                            <p className="text-gray-600 text-center"></p>

                            <div className='my-5'>
                                <ThemeProvider theme={theme}>
                                    <Button fullWidth
                                        title={`Order ${product.primaryText} | Shen Shen Ice Cream`}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        sx={{ ml: 'auto' }}
                                        style={customFontStyle}

                                    >
                                        Order Now
                                    </Button>
                                </ThemeProvider>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
