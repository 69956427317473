import { React, useState } from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import Footer from './Component/Footer';
import Home from './Home';
import About from './About';
import Products from './Products';
import Contact from './Contact';
import Reviews from './Reviews';
import HeadShow from './Component/HeadShow';

//Cinderella #fdcfd1
// + Foam #CFFCFA

// Nav Clam Shell #D6AFB1


const history = createBrowserHistory();

function App() {

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className='bg-[#fdcfd1]'>
      <BrowserRouter history={history}>
        <div className='bg-[#D6AFB1] text-[#CFFCFA]'>
          <nav className="flex items-center justify-between flex-wrap bg-[#D6AFB1] p-5">

            <div className="block lg:hidden print:hidden">
              <button
                className="flex items-center px-3 py-2 border rounded text-black border-gray-400 hover:text-white hover:border-white"
                onClick={toggleMenu}
              >
                <svg className="w-4 h-4 fill-current" viewBox="0 0 24 24">
                  <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
                </svg>
              </button>
            </div>
            <div
              className={`w-full flex-grow lg:flex lg:items-center lg:w-auto ${menuOpen ? "" : "hidden"
                } mt-2 lg:mt-0 lg:justify-center`}
              id="nav-content"
            >
              <ul className="list-reset lg:flex justify-center flex-1 items-center custom-font font-bold text-3xl" >
                <li className="mr-3">
                  <a title='Shen Shen Ice Cream'
                    className="inline-block py-2 px-4 text-xl text-black hover:text-[#CFFCFA] no-underline"
                    href="/Home"
                  >
                    Home
                  </a>
                </li>
                <li className="mr-3">
                  <a title='Discover Our Story'
                    className="inline-block text-black text-xl no-underline hover:text-[#CFFCFA] hover:text-underline py-2 px-4"
                    href="/About"
                  >
                    About
                  </a>
                </li>
                <li className="mr-3">
                  <a title='Delight in Irresistible Flavors'
                    className="inline-block text-black text-xl no-underline hover:text-[#CFFCFA] hover:text-underline py-2 px-4"
                    href="/Products"
                  >
                    Products
                  </a>
                </li>

                <li className="mr-3">
                  <a title='Customer Testimonials'
                    className="inline-block text-black text-xl no-underline hover:text-[#CFFCFA] hover:text-underline py-2 px-4"
                    href="/Reviews"
                  >
                    Reviews
                  </a>
                </li>


                <li className="mr-3">
                  <a title='Get in Touch'
                    className="inline-block text-black text-xl no-underline hover:text-[#CFFCFA] hover:text-underline py-2 px-4"
                    href="/Contact"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div><HeadShow /></div>

        <Switch>
          <Route exact="true" path="/" component={Home} />
          <Route exact="true" path="/Home" component={Home} />
          <Route exact="true" path="/About" component={About} />
          <Route exact="true" path="/Products" component={Products} />
          <Route exact="true" path="/Contact" component={Contact} />
          <Route exact="true" path="/Reviews" component={Reviews} />

        </Switch>
      </BrowserRouter>








      <div><Footer /></div>
    </div>
  );
}

export default App;
