import React from 'react'
import { Helmet } from 'react-helmet'



export default function Reviews() {

    return (
        <div className='p-12'>
            <Helmet>
                <title data-react-helmet="true">Customer Reviews | Shen Shen Ice Cream, Hurghada, Egypt</title>
                <meta data-react-helmet="true" name="description" content="Read what our customers have to say about Shen Shen Ice Cream! Experience the joy of our excellent quality, rich flavors, and outstanding staff. Discover why we are known for serving the best homemade ice cream in Hurghada, Egypt. Check out the reviews from Wickie Zebitz and JD Javed Chaudhry." />
                <meta data-react-helmet="true" property="og:title" content="Customer Reviews | Shen Shen Ice Cream, Hurghada, Egypt" />
                <meta data-react-helmet="true" property="og:description" content="Read what our customers have to say about Shen Shen Ice Cream! Experience the joy of our excellent quality, rich flavors, and outstanding staff. Discover why we are known for serving the best homemade ice cream in Hurghada, Egypt. Check out the reviews from Wickie Zebitz and JD Javed Chaudhry." />
                <meta data-react-helmet="true" property="og:image" content="https://webdesignexpressions.net/Assets/ShenShen.jpg" />
                <meta data-react-helmet="true" property="og:url" content="https://shenshen.webdesignexpressions.net/Reviews" />
                <meta data-react-helmet="true" property="og:type" content="website" />
            </Helmet>


            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fwzebitz%2Fposts%2F10157608561964080&show_text=true&width=100%" width="100%" height="auto" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fnina.divjak%2Fposts%2F3939209062764382&show_text=true&width=100%" width="100%" height="auto" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fjaved.chaudhry.12%2Fposts%2F10161374431234619&show_text=true&width=100%" width="100%" height="auto" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fjb.babi%2Fposts%2F3541058339277763&show_text=true&width=100%" width="100%" height="530" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3D3081441068633936%26id%3D100003042190777&show_text=true&width=100%" width="100%" height="auto" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fyahya.zakareya%2Fposts%2F10164219215195611&show_text=true&width=100%" width="100%" height="auto" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FWifeofMoustafa%2Fposts%2F10158064105624531&show_text=true&width=100%" width="100%" height="auto" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmostafa.hamouda.7%2Fposts%2F3456067024414636&show_text=true&width=100%" width="100%" height="auto" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Famira.abdulmaksoud%2Fposts%2F10157401689462190&show_text=true&width=100%" width="100%" height="auto" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3D2633483573628375%26id%3D100009002739529&show_text=true&width=100%" width="100%" height="auto" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3D934660700388272%26id%3D100015330513876&show_text=true&width=100%" width="100%" height="170" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmichelle.hyllested%2Fposts%2F10224251207851231&show_text=true&width=100%" width="100%" height="auto" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fvanessa.monetti.3%2Fposts%2F10223637265669898&show_text=true&width=100%" width="100%" height="auto" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fadela.sediva.3%2Fposts%2F10219757934392890&show_text=true&width=100%" width="100%" height="auto" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fszilvia.karcagi.7%2Fposts%2F4121371664556326&show_text=true&width=100%" width="100%" height="auto" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fbabette.sindram%2Fposts%2F10222152853547286&show_text=true&width=100%" width="100%" height="auto" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Ftamer.elzien%2Fposts%2F10214032234334877&show_text=true&width=100%" width="100%" height="107" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmaha.fawzy.790%2Fposts%2F10157491561780840&show_text=true&width=100%" width="100%" height="107" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Famgad.esprix%2Fposts%2F3026369224110377&show_text=true&width=100%" width="100%" height="107" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsabry.saad.10%2Fposts%2F2355659431116963&show_text=true&width=100%" width="100%" height="107" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>
            <div className='mb-1'><iframe title='ShenShen Hurghada Review' src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fnastja.emelyanova%2Fposts%2F1762783803770012&show_text=true&width=100%" width="100%" height="136" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></div>

        </div>
    )
}
